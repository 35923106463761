
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { ElNotification } from 'element-plus/es'
// import { Company } from '@/store/modules/CompanyModule'

interface NewEmployee {
    userUuid: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    userRole: string;
    managerFirstName?: string;
    managerLastName?: string;
    managerEmail?: string;
    costCentre?: string;
    department?: string;
    division?: string;
    businessUnit? : string;
}

export default defineComponent({
  name: 'the-edit-employee-form',
  props: {
    employee: Object
  },
  setup (props, { emit }) {
    const store = useStore()
    const formRef = ref<null | HTMLFormElement>(null)
    const newTargetModalRef = ref<null | HTMLElement>(null)
    const loading = ref<boolean>(false)
    const employeeToEdit = ref(props.employee) as any
    const roleLabels = [] as any
    roleLabels[1] = 'Admin'
    roleLabels[2] = 'Trainer'
    roleLabels[3] = 'Instructional Designer'
    roleLabels[4] = 'Consultant (external)'
    roleLabels[5] = 'Contributor (manager)'
    const targetData = reactive({
      employee: employeeToEdit.value,
      manager: { firstName: employeeToEdit.value.managerFirstName, lastName: employeeToEdit.value.managerLastName, email: employeeToEdit.value.managerEmail },
      costCentre: employeeToEdit.value.costCentre,
      department: employeeToEdit.value.department,
      division: employeeToEdit.value.division,
      businessUnit: employeeToEdit.value.businessUnit
    }) as any
    targetData.employee.role = { roleId: targetData.employee.userRole, label: roleLabels[parseInt(targetData.employee.userRole)] }
    watch(props, (newValue, oldValue) => {
      employeeToEdit.value = props.employee
      targetData.employee = props.employee
    })
    const rules = ref({
      'employee.firstName': [
        {
          required: true,
          message: 'Please input employee first name',
          trigger: 'blur'
        }
      ],
      'employee.lastName': [
        {
          required: true,
          message: 'Please input employee last name',
          trigger: 'blur'
        }
      ],
      'employee.email': [
        {
          required: true,
          message: 'Please input the Email address',
          trigger: 'blur'
        }
      ],
      'employee.role': [
        {
          required: false,
          message: 'Please select a role',
          trigger: 'blur'
        }
      ],
      'manager.firstName': [
        {
          required: false,
          message: 'Please input manager first name',
          trigger: 'blur'
        }
      ],
      'manager.lastName': [
        {
          required: false,
          message: 'Please input manager last name',
          trigger: 'blur'
        }
      ],
      'manager.email': [
        {
          required: false,
          message: 'Please input the Email address',
          trigger: 'blur'
        }
      ],
      costCentre: [
        {
          required: false
        }
      ],
      department: [
        {
          required: false
        }
      ],
      division: [
        {
          required: false
        }
      ],
      businessUnit: [
        {
          required: false
        }
      ]

    })

    const submit = async (values) => {
      if (!formRef.value) {
        return
      }
      let formValidation = false
      formRef.value.validate((valid) => {
        if (!valid) {
          formValidation = false
          return false
        }
        loading.value = true
        formValidation = true
      })
      if (!formValidation) {
        // await store.commit(Mutations.SET_COMPANY_ERRORS, {})

        Swal.fire({
          text: 'Sorry, looks like there are some errors detected, please try again.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        loading.value = false

        return
      }

      const payload = reactive({
        firstName: targetData.employee.firstName,
        lastName: targetData.employee.lastName,
        userEmail: targetData.employee.email,
        userRole: targetData.employee.role.roleId
      }) as unknown as NewEmployee

      payload.userUuid = targetData.employee.userUuid

      if (employeeToEdit.value.managerFirstName !== '' && employeeToEdit.value.managerFirstName !== null) {
        payload.managerFirstName = employeeToEdit.value.managerFirstName
      }
      if (employeeToEdit.value.managerLastName !== '' && employeeToEdit.value.managerLastName !== null) {
        payload.managerLastName = employeeToEdit.value.managerLastName
      }

      if (employeeToEdit.value.managerEmail !== '' && employeeToEdit.value.managerEmail !== null) {
        payload.managerEmail = employeeToEdit.value.managerEmail
      }

      if (employeeToEdit.value.costCentre !== '' && employeeToEdit.value.costCentre !== null) {
        payload.costCentre = employeeToEdit.value.costCentre
      }
      if (employeeToEdit.value.department !== '' && employeeToEdit.value.department !== null) {
        payload.department = employeeToEdit.value.department
      }
      if (employeeToEdit.value.division !== '' && employeeToEdit.value.division !== null) {
        payload.division = employeeToEdit.value.division
      }
      if (employeeToEdit.value.businessUnit !== '' && employeeToEdit.value.businessUnit !== null) {
        payload.businessUnit = employeeToEdit.value.businessUnit
      }
      store.dispatch(Actions.API_UPDATE_EMPLOYEE, payload).then((response) => {
        formRef.value?.resetFields()
        formRef.value?.clearValidate()
        emit('success', response.payload)
        ElNotification.success({
          message: 'Employee updated',
          dangerouslyUseHTMLString: true,
          customClass: 'houston-notification',
          position: 'bottom-left'
        })
        loading.value = false
      }).catch((response) => {
        loading.value = false
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
        loading.value = false
      })
    }

    const employeeRoles = Array.from({ length: 5 }, (_, i) => {
      const n = i + 1

      return {
        roleId: `${n}`,
        label: roleLabels[n]
      }
    })

    const onClearValidation = () => {
      formRef.value?.resetFields()
      formRef.value?.clearValidate()
    }
    onMounted(() => {
    })

    return {
      targetData,
      submit,
      onClearValidation,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      employeeRoles,
      employeeToEdit
    }
  }
})
